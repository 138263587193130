import React from "react";
import { graphql } from "gatsby";
import { mainBlue, mainOrange, screen } from "../components/common/variables";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import SEO from "../components/seo";
import PageTitle from "../components/page-title";
import FeaturedProducts from "../components/featured-products";
import GetInTouch from "../components/get-in-touch";
import AnniversaryBadge from "../images/svg/thermic-anniv.svg";

const Wrapper = styled.div`
  padding-bottom: 110px;
  padding-top: 87px;
  @media ${screen.xsmall} {
    padding-bottom: 170px;
  }
  @media ${screen.small} {
    padding-top: 80px;
  }
  @media ${screen.xlarge} {
    padding-bottom: 200px;
  }

  .content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-left: 35px;
    padding-right: 35px;
    position: relative;
    padding-top: 260px;
    padding-bottom: 70px;
    @media ${screen.xsmall} {
      padding-bottom: 140px;
      padding-left: 70px;
      padding-right: 70px;
      padding-top: 400px;
    }
    @media ${screen.small} {
      padding-top: 82px;
    }
    @media ${screen.medium} {
      padding-top: 100px;
      padding-left: 160px;
      padding-right: 160px;
      padding-bottom: 110px;
    }
    @media ${screen.xlarge} {
      padding-bottom: 140px;
      padding-top: 140px;
      padding-left: 280px;
      padding-right: 280px;
    }

    &__text {
      max-width: 580px;
      width: 100%;
      position: relative;
      z-index: 2;
      @media ${screen.xsmall} {
        max-width: 650px;
      }
      @media ${screen.small} {
        max-width: 580px;
      }
      @media ${screen.xlarge} {
        max-width: 660px;
      }

      .about-heading {
        color: ${mainOrange};
        font-weight: 800;
        font-size: 2rem;
        line-height: 1.25;
        @media ${screen.xsmall} {
          font-size: 2.9rem;
          max-width: 430px;
        }
        @media ${screen.small} {
          font-size: 3.4rem;
          max-width: inherit;
        }
        @media ${screen.xlarge} {
          font-size: 3.73rem;
        }
      }

      .about-intro {
        color: ${mainBlue};
        font-size: 1.17rem;
        font-weight: 300;
        margin-top: 30px;
        @media ${screen.xsmall} {
          font-size: 1.4rem;
        }
        @media ${screen.xlarge} {
          font-size: 1.5rem;
          margin-top: 40px;
        }
      }

      .about-description {
        color: ${mainBlue};
        margin-top: 30px;
        @media ${screen.xsmall} {
          margin-top: 40px;
        }
        @media ${screen.xlarge} {
          margin-top: 50px;
        }

        p {
          font-size: 1.08rem;
          margin-bottom: 18px;
          @media ${screen.xsmall} {
            font-size: 1.08rem;
            margin-bottom: 22px;
          }
          @media ${screen.xlarge} {
            font-size: 1.25rem;
            margin-bottom: 30px;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .about-badge {
        max-width: 150px;
        margin-top: 20px;
        @media ${screen.xsmall} {
          max-width: 180px;
        }
      }

      .about-extra {
        color: ${mainBlue};
        font-size: 1.6rem;
        margin-top: 20px;
        @media ${screen.xsmall} {
          font-size: 2.2rem;
        }
        @media ${screen.xlarge} {
          font-size: 2.4rem;
        }
      }
    }

    &__images {
      width: 100%;
      position: absolute;
      right: 0;
      top: -13px;
      @media ${screen.xsmall} {
        max-width: 350px;
        top: 40px;
        right: 70px;
      }
      @media ${screen.small} {
        position: static;
        max-width: 570px;
        margin-left: 60px;
      }
      @media ${screen.medium} {
        max-width: 520px;
      }
      @media ${screen.large} {
        max-width: 570px;
      }

      .about-portrait-img {
        max-width: 590px;
        width: 100%;
        display: none;
        @media ${screen.xsmall} {
          display: block;
        }
        img {
          width: 100%;
        }

        &--mobile {
          display: block;
          width: 100%;
          @media ${screen.xsmall} {
            display: none;
          }
        }
      }
    }
  }
`;

const Enquiry = styled.div`
  color: ${mainBlue};
  padding-top: 20px;
  padding-left: 35px;
  padding-right: 35px;
  @media ${screen.xsmall} {
    padding-top: 110px;
    padding-left: 70px;
    padding-right: 70px;
  }
  @media ${screen.small} {
    padding-left: 50px;
    padding-right: 50px;
  }
  @media ${screen.medium} {
    padding-left: 160px;
    padding-right: 160px;
  }
  @media ${screen.xlarge} {
    padding-top: 140px;
    padding-left: 280px;
    padding-right: 280px;
  }
`;

const AboutPage = ({ data }) => {
  const {
    introduction,
    description,
    extra,
    portrait_featured_image,
    featured_image_portrait_mobile,
    title_tag,
    meta_description,
  } = data.main.data;

  return (
    <Layout>
      <SEO title={title_tag} description={meta_description} />
      <Wrapper>
        <div className="content">
          <div className="content__text">
            <PageTitle>About</PageTitle>
            <h1 className="about-heading">AUSTRALIAN THERMIC LANCE COMPANY</h1>
            <h3 className="about-intro">{introduction}</h3>
            <div
              className="about-description"
              dangerouslySetInnerHTML={{ __html: description.html }}
            />
            <div className="about-badge">
              <AnniversaryBadge />
            </div>
            <h6 className="about-extra">{extra}</h6>
          </div>
          <div className="content__images">
            <div className="about-portrait-img">
              {portrait_featured_image.gatsbyImageData && (
                <GatsbyImage
                  image={portrait_featured_image.gatsbyImageData}
                  alt={portrait_featured_image.alt || "Banner"}
                />
              )}
            </div>
            <div className="about-portrait-img about-portrait-img--mobile">
              {featured_image_portrait_mobile.gatsbyImageData && (
                <GatsbyImage
                  image={featured_image_portrait_mobile.gatsbyImageData}
                  alt={featured_image_portrait_mobile.alt || "Banner"}
                />
              )}
            </div>
          </div>
        </div>
        <FeaturedProducts heading={`Shop Our Products`} />
        <Enquiry>
          <GetInTouch
            heading={`Got questions?`}
            subheading={`Get in touch with our team, we'd love to help.`}
            linkLabel={`SAY HELLO`}
          />
        </Enquiry>
      </Wrapper>
    </Layout>
  );
};

export default AboutPage;

export const dataQuery = graphql`
  {
    main: prismicAboutPage {
      data {
        introduction
        description {
          html
          text
        }
        extra
        portrait_featured_image {
          alt
          gatsbyImageData(layout: FULL_WIDTH)
        }
        featured_image_portrait_mobile {
          alt
          gatsbyImageData(layout: FULL_WIDTH)
        }
        title_tag
        meta_description
      }
    }
  }
`;
